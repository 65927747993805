enum Actions {
  // action types
  GET_PROJECT = "handleGetProject",
  CREATE_PROJECT = "handleCreateProject",
  READ_ALL_PROJECT = "handleReadAllProject",
  READ_USER_PROJECT = "handleReadUserProject",
  UPDATE_PROJECT = "handleUpdateProject",
  DELETE_PROJECT = "handleDeleteProject",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  ADD_PROJECT = "addProject",
  SET_PROJECTS = "setProjects",
  UPDATE_PROJECT = "updateProject",
  REMOVE_PROJECT = "removeProject",
}

export { Actions, Mutations };
