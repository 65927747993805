enum Actions {
  // action types
  GET_CONTRACT = "handleGetContract",
  CREATE_CONTRACT = "handleCreateContract",
  READ_ALL_CONTRACT = "handleReadAllContract",
  READ_USER_CONTRACT = "handleReadUserContract",
  UPDATE_CONTRACT = "handleUpdateContract",
  DELETE_CONTRACT = "handleDeleteContract",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  ADD_CONTRACT = "addContract",
  SET_CONTRACTS = "setContracts",
  UPDATE_CONTRACT = "updateContract",
  REMOVE_CONTRACT = "removeContract",
}

export { Actions, Mutations };
