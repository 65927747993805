import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  Actions as AggridActions,
  Mutations as AggridMutations,
} from "@/store/enums/AggridEnums";

import { useToast, POSITION, TYPE } from "vue-toastification";
import { GridOptions } from "ag-grid-community";

import BaseToaster from "@/components/notifications/BaseToaster.vue";

interface Alert {
  type: TYPE;
  title: string | null;
  allowUndo: boolean;
  duration: number;
  api?: GridOptions;
}

interface StoreInfo {
  queue: Alert[];
  alert: Alert;
  undoAction: boolean;
}

const toast = useToast();

@Module
export default class AlertModule extends VuexModule implements StoreInfo {
  errors = [] as string[];
  alert = {} as Alert;
  queue = [] as Alert[];
  undoAction = false;

  /**
   * Get  errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [AggridMutations.SET_UNDO_MUTATION]() {
    console.log("set undo mutation");
    this.undoAction = true;
  }

  @Mutation
  [Mutations.REMOVE_ERROR_ACTION]() {
    this.errors.shift();
  }

  @Mutation
  [Mutations.SET_ERROR](payload) {
    console.log("error", payload);
    this.errors.push(payload);
  }

  @Mutation
  [AggridMutations.RESET_UNDO_MUTATION]() {
    this.undoAction = false;
    // this.queue[index].showAlert = false;
    // this.queue.splice(index, 1);
  }

  @Action({ rawError: true })
  [AggridActions.SET_UNDO_ACTION](payload) {
    return new Promise<string>((resolve) => {
      const toastEl = toast(
        {
          component: BaseToaster,
          props: {
            title: payload.title,
            buttonTitle: payload.buttonTitle,
            allowUndo: payload.allowUndo,
          },
          listeners: {
            undoClicked: () => {
              this.context.commit(AggridMutations.SET_UNDO_MUTATION);
              payload.api.undoCellEditing();
              resolve("undo");
              toast.clear();
            },
          },
        },
        {
          position: POSITION.TOP_RIGHT,
          timeout: payload.duration,
          type: payload.type,
          onClose: () => {
            this.context.commit(AggridMutations.RESET_UNDO_MUTATION);
            resolve(`test`);
          },
        }
      );
      return toastEl;
    });
  }

  @Action({ rawError: true })
  [AggridActions.RESET_UNDO_ACTION]() {
    console.log("RESET UNDO ACTION");
    this.context.commit(AggridMutations.RESET_UNDO_MUTATION);
  }

  @Action({ rawError: true })
  [Actions.SET_TOASTER_ACTION](payload: Alert) {
    return new Promise<string>((resolve) => {
      const toastEl = toast(
        {
          component: BaseToaster,
          props: {
            title: payload.title,
          },
        },
        {
          position: POSITION.TOP_RIGHT,
          timeout: payload.duration,
          type: payload.type,
          onClose: () => {
            this.context.commit(Mutations.REMOVE_ERROR_ACTION);
            resolve(`test`);
          },
        }
      );
      return toastEl;
    });
  }

  @Action({ rawError: true })
  [Actions.SET_ERROR_ACTION](payload) {
    this.context.commit(Mutations.SET_ERROR, payload);
  }
}
