enum Actions {
  // action types
  SET_UNDO_ACTION = "setUndoAction",
  RESET_UNDO_ACTION = "resetUndoAction",
  SET_CELLVALUE_ACTION = "setCellValueAction",
  UPDATE_COMPONENTKEY_ACTION = "updateComponentKeyAction",
  GET_TABLESTATE_ACTION = "getTableStateAction",
  SAVE_TABLESTATE_ACTION = "saveTableStateAction",
}

enum Mutations {
  // mutation types
  SET_UNDO_MUTATION = "setUndoMutation",
  RESET_UNDO_MUTATION = "resetUndoMutation",
  SET_CELLVALUE_MUTATION = "setCellValueMutation",
  UPDATE_COMPONENTKEY_MUTATION = "updateComponentKeyMutation",
  SET_TABLESTATE_MUTATION = "setTableStateMutation",
}

export { Actions, Mutations };
