enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  VERIFY_EMAIL = "verifyEmail",
  REFRESH_TOKEN = "refreshToken",
  GET_PROFILE = "getProfileActions",
  UPDATE_LANGUAGE = "updateLanguageActions",
  GET_OAUTH_TOKEN = "getOauthToken",
  SAVE_PREFERRED_TENANT = "savePreferredTenantActions",
  GET_USER_TENANTS = "getAllUserTenants",
  CHECK_SITE_CREATED = "checkSiteCreated",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  REGISTER_CONTRACTOR = "registerContractor",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_TOASTER_ACTION = "setToasterAction",
  SET_ERROR_ACTION = "setErrorAction",
  CHECK_ACCOUNT_EXISTS = "checkAccountExists",
  RECAPTCHA_VERIFY = "recaptchaVerify",
}

enum Mutations {
  // mutation types
  REMOVE_ERROR_ACTION = "removeErrorMutation",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  PURGE_LOCAL_PROFILE = "removeLocalProfile",
  SET_AUTH = "setAuth",
  SET_TOKEN = "setToken",
  SET_USER = "setUser",
  SET_TENANTS = "setTenantsMutation",
  SET_PREFERRED_TENANT = "setPreferredTenantMutation",
  SET_VERIFIED = "setEmailVerified",
  SET_PROFILE = "setProfileMutation",
  UPDATE_LANGUAGE = "setLanguageMutation",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_WINDOW_MUTATION = "setWindowMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

export { Actions, Mutations };
