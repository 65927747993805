enum Actions {
  // action types
  GET_USER = "handleGetUserActions",
  CREATE_USER = "handleCreateUserActions",
  GET_ALL_USER = "handleReadAllUserActions",
  UPDATE_USER = "handleUpdateUserActions",
  SET_ACTIVATION_STATUS = "handleUserActivationStatusActions",
  DELETE_USER = "handleDeleteUserActions",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  ADD_USER = "addUserMutations",
  SET_USERS = "setUsersMutations",
  UPDATE_USER = "updateUserMutations",
  SET_ACTIVATION_STATUS = "handleUserActivationStatusMutations",
  REMOVE_USER = "removeUserMutations",
}

export { Actions, Mutations };
