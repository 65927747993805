<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
// @import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~vue-toastification/dist/index.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins.scss";
@import "assets/sass/style.scss";
@import "assets/sass/custom-style.scss";
</style>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, watch, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";
import ApiService from "@/core/services/ApiService";
import AuthApiService from "@/core/services/AuthApiService";
import TitaniumApiService from "@/core/services/TitaniumApiService";
import CookieService from "@/core/services/CookieService";
import { MenuComponent } from "@/assets/ts/components/index";
import { removeModalBackdrop, removeDrawerBackdrop } from "@/core/helpers/dom";
import "element-plus/dist/index.css";


export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const onResize = () => {
      store.commit(Mutations.SET_WINDOW_MUTATION);
    };

    const errors = computed(() => {
      return store.getters.getErrors
    })

    onMounted(() => {
      // Mount interceptors
      ApiService.setRequestHeader();
      ApiService.checkAuthorizedResponse();
      AuthApiService.setRequestHeader();
      AuthApiService.checkAuthorizedResponse();
      TitaniumApiService.setRequestHeader();
      TitaniumApiService.checkAuthorizedResponse();

      if (store.getters.availableTenants.length === 0 && store.getters.currentUser.tenant) {
        store.dispatch(Actions.GET_USER_TENANTS)
      }
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      window.addEventListener("resize", onResize, { passive: true });
    });

    //watch global errors array and display toast
    watch(errors.value, (val, oldVal) => {
      if (val && errors.value.length > 0) {
        store.dispatch(Actions.SET_TOASTER_ACTION, {
          type: "error",
          title: errors.value[0],
          allowUndo: false,
          duration: 5000,
        });
      }
    }, {
      deep: true
    })

    watch(
      () => route.path,
      () => {
        console.log('path changed')
        MenuComponent.hideDropdowns(undefined);
        // overflow.value = route.meta.oldView ? 'hidden' : 'auto'
        document.getElementById("app")!.style.overflow = route.meta.oldView ? 'hidden' : ''
        window["vue_instance"].tenant = store.getters.currentUser.tenant;

        removeModalBackdrop();
        removeDrawerBackdrop();
      }
    );

    // watchEffect(() => {
    //   // If both access token and refresh token do not exist return to sign in page
    //   if (!store.state.AuthModule.token.access_token && !store.state.AuthModule.token.refresh_token) {
    //     router.replace({ name: "sign-in" })
    //   } else if (!store.state.AuthModule.token.access_token && store.state.AuthModule) {
    //     // Access token expired but refresh token exists 
    //     // Make call to refresh token endpoint to get new access_token
    //   }
    // })

    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });
  },
});
</script>
