import ApiService from "@/core/services/ApiService";
import TitaniumApiService from "@/core/services/TitaniumApiService";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  Actions as AdminActions,
  Mutations as AdminMutations,
} from "@/store/enums/AdminEnums";
import { AdminInfo } from "@/store/interface/StoreInterfaces";
import { User } from "@/core/interfaces/UserInterface";

@Module
export default class AdminModule extends VuexModule implements AdminInfo {
  users = [] as User[];

  /**
   * Get all users
   * @returns object
   */
  get getUsers(): User[] {
    return this.users;
  }

  @Mutation
  [AdminMutations.SET_USERS](payload) {
    this.users = payload;
  }

  @Action
  [AdminActions.GET_ALL_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`titanium/users`, {})
        .then(({ data }) => {
          this.context.commit(AdminMutations.SET_USERS, data);
          resolve(data);
        })
        .catch((response) => {
          console.log(response);
          this.context.commit(
            Mutations.SET_ERROR,
            response || response.data.message
          );
          reject();
        });
    });
  }

  @Action
  async [AdminActions.CREATE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.post(`create/user`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  async [AdminActions.UPDATE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.post(`edit/user`, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  async [AdminActions.DELETE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.post(`delete/user`, payload.id)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  async [AdminActions.SET_ACTIVATION_STATUS](payload) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.post(`state/user`, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
}
