import TitaniumApiService from "@/core/services/TitaniumApiService";
import AuthApiService from "@/core/services/AuthApiService";
// import OtherAuthApiService from "@/core/services/OtherApiService";
import JwtService from "@/core/services/JwtService";
import CookieService from "@/core/services/CookieService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  UserData,
  Token,
  UserAuthInfo,
  TenantData,
} from "@/store/interface/StoreInterfaces";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import axios from "axios";

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = {} as UserData;
  tenants = [] as TenantData[];
  token = {} as Token;
  isAuthenticated = !!CookieService.getCookie("refresh_token");

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): UserData {
    // console.log(this.user);
    return this.user;
  }

  /**
   * Get user's tenants
   * @returns User
   */
  get availableTenants(): TenantData[] {
    // console.log(this.user);
    return this.tenants;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user's email
   * @returns boolean
   */
  get isEmailVerified(): boolean {
    return this.user.email_verified_at;
  }

  @Mutation
  [Mutations.SET_AUTH]() {
    this.isAuthenticated = true;
  }

  @Mutation
  [Mutations.SET_TOKEN](token) {
    this.token = token;
    CookieService.saveCookie(token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_TENANTS](tenants) {
    this.tenants = tenants;
  }

  @Mutation
  [Mutations.SET_PREFERRED_TENANT](tenant) {
    this.user["tenant"] = tenant;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    console.log("purge_auth mutation");
    this.isAuthenticated = false;
    this.user = {} as UserData;
  }

  @Mutation
  [Mutations.UPDATE_LANGUAGE](payload) {
    this.user.language = payload.language;
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      /* eslint-disable @typescript-eslint/camelcase */

      AuthApiService.post("login", credentials)
        .then(({ data }) => {
          const tokenData = {
            access_token: data["access_token"],
            expires_in: data["expires_in"],
            refresh_token: data["refresh_token"],
            token_type: data["token_type"],
          };

          if (!data["user"]["email_verified_at"]) {
            return reject(
              new Error(
                "Your email is not verified! Please verify your email address or email an administrator at admin@bauwise.com."
              )
            );
          }

          this.context.commit(Mutations.SET_TOKEN, tokenData);

          this.context.dispatch(Actions.CHECK_SITE_CREATED).then((response) => {
            if (!data["tenant"]) {
              this.context.commit(Mutations.SET_TENANTS, data["tenants"]);
              this.context.commit(Mutations.SET_AUTH);
              this.context.commit(Mutations.SET_USER, data["user"]);
              return resolve();
            }
            if (
              !response.data["site_created"] &&
              !response.data["legacy_migrated_at"]
            ) {
              Swal.fire({
                text: "Your site is currently being created!",
                icon: "info",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                },
              }).then(() => {
                this.context.dispatch(Actions.LOGOUT);
              });
              return reject(new Error("Your site is currently being created!"));
            }
            this.context.commit(Mutations.SET_TENANTS, data["tenants"]);
            this.context.commit(Mutations.SET_AUTH);
            this.context.commit(Mutations.SET_USER, data["user"]);
            resolve();
          });
        })
        .catch((response) => {
          reject(new Error(response.message));
        });
      // resolve();
    });
  }

  @Action
  [Actions.LOGOUT](payload) {
    if (!CookieService.getCookie("access_token")) return;

    AuthApiService.post("/logout", payload)
      .then(() => {
        this.context.commit(Mutations.PURGE_AUTH);
        JwtService.destroyToken();
        //Clears all cookies
        document.cookie
          .split(";")
          .forEach(
            (cookie) =>
              (document.cookie = cookie
                .replace(/^ +/, "")
                .replace(
                  /=.*/,
                  `=;expires=${new Date(0).toUTCString()};path=/`
                ))
          );
        location.reload();
      })
      .catch(({ response }) => {
        // console.log(response);
        // this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.message || response.data.error
        );
      });
  }

  @Action({ rawError: true })
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post("register", credentials)
        .then(({ data }) => {
          console.log("register", data);
          // this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          // console.log(response);
          // this.context.commit(
          //   Mutations.SET_ERROR,
          //   response.data.message || response.data.email
          // );
          reject(new Error(response.data.message || response.data.email));
        });
    });
  }

  @Action
  [Actions.REGISTER_CONTRACTOR](credentials) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post("external/register", credentials)
        .then(({ data }) => {
          console.log("registerContract", data);
          const tokenData = {
            access_token: data["access_token"],
            expires_in: data["expires_in"],
            refresh_token: data["refresh_token"],
            token_type: data["token_type"],
          };
          this.context.commit(Mutations.SET_TOKEN, tokenData);
          this.context.commit(Mutations.SET_TENANTS, data["tenants"]);
          this.context.commit(Mutations.SET_AUTH);
          this.context.commit(Mutations.SET_USER, data["user"]);
          // this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          // console.log(response);
          // this.context.commit(
          //   Mutations.SET_ERROR,
          //   response.data.message || response.data.email
          // );
          reject(new Error(response.data.message || response.data.email));
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post("forgot/password", payload)
        .then(({ data }) => {
          console.log("forgotPassword", data);
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(
            Mutations.SET_ERROR,
            response.data.data.error || response.data.message
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.RESET_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post("reset/password", payload)
        .then(({ data }) => {
          console.log("resetPassword", data);
          const tokenData = {
            access_token: data["access_token"],
            expires_in: data["expires_in"],
            refresh_token: data["refresh_token"],
            token_type: data["token_type"],
          };
          this.context.commit(Mutations.SET_TOKEN, tokenData);
          this.context.commit(Mutations.SET_TENANTS, data["tenants"]);
          this.context.commit(Mutations.SET_AUTH);
          this.context.commit(Mutations.SET_USER, data["user"]);

          resolve();
        })
        .catch(({ response }) => {
          console.log("resetPassword", response);
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    if (CookieService.getCookie("refresh_token")) {
      try {
        const response = await AuthApiService.query("validate", {});
        this.context.commit(Mutations.SET_AUTH);
        return Promise.resolve(response);
      } catch (response) {
        console.log(response);
        this.context.commit(
          Mutations.SET_ERROR,
          "Token Expired. Please relog."
        );
        this.context.commit(Mutations.PURGE_AUTH);
        return Promise.reject(response.data.message);
      }
    } else {
      console.log("purge auth");
      this.context.commit(Mutations.SET_ERROR, "Token Expired. Please relog.");
      this.context.commit(Mutations.PURGE_AUTH);
      return Promise.reject("Not Logged In");
    }
  }

  @Action({ rawError: true })
  [Actions.VERIFY_EMAIL](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post("email/verify", payload)
        .then((response) => {
          console.log("emailVerified", response);
          this.context.commit(Mutations.SET_VERIFIED, response);
          resolve();
        })
        .catch(({ response }) => {
          console.log("emailVerified", response);
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  async [Actions.REFRESH_TOKEN](payload) {
    if (CookieService.getCookie("refresh_token")) {
      try {
        const response = await AuthApiService.post("refresh_token", payload);
        const token = response.data;
        this.context.commit(Mutations.SET_TOKEN, token);
        this.context.commit(Mutations.SET_AUTH);
        return Promise.resolve(response);
      } catch (response) {
        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, response.error);
        return Promise.reject(response.error);
      }
    } else {
      console.log("purge auth");
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_LANGUAGE](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post(`update_language`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.UPDATE_LANGUAGE, payload);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  async [Actions.SAVE_PREFERRED_TENANT](payload) {
    try {
      const response = await AuthApiService.post("user/tenant", payload);
      this.context.commit(Mutations.SET_PREFERRED_TENANT, payload.tenant);
      window["vue_instance"].tenant = payload.tenant;
      return Promise.resolve(response);
    } catch (response) {
      this.context.commit(Mutations.PURGE_AUTH);
      this.context.commit(Mutations.SET_ERROR, response.error);
      return Promise.reject(response.error);
    }
  }

  @Action
  async [Actions.CHECK_SITE_CREATED](payload) {
    try {
      const response = await TitaniumApiService.query("site/check", payload);
      return Promise.resolve(response);
    } catch (response) {
      this.context.commit(Mutations.SET_ERROR, response.error);
      return Promise.reject(response.error);
    }
  }

  @Action
  async [Actions.CHECK_ACCOUNT_EXISTS](payload) {
    try {
      const response = await AuthApiService.query("link/token", {
        params: payload,
      });
      return Promise.resolve(response);
    } catch (response) {
      console.log(response.message);
      this.context.commit(Mutations.SET_ERROR, response.message);
      return Promise.reject(response.message);
    }
  }

  @Action
  async [Actions.GET_USER_TENANTS](payload) {
    try {
      const response = await AuthApiService.query("user/tenants", {});
      console.log(response);
      this.context.commit(Mutations.SET_TENANTS, response.data);
      return Promise.resolve(response);
    } catch (response) {
      this.context.commit(Mutations.SET_ERROR, response.error);
      return Promise.reject(response.error);
    }
  }

  @Action
  async [Actions.RECAPTCHA_VERIFY](payload) {
    try {
      const response = await axios.post(
        `https://auth.bauwise.com:8000/recaptcha/siteverify`,
        {
          response: payload,
        }
      );
      return Promise.resolve(response);
    } catch (response) {
      // this.context.commit(Mutations.SET_ERROR, response.error);
      return Promise.reject(response.error);
    }
  }
}
