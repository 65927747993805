import { computed } from "vue";
import store from "@/store/index";
import i18n from "@/core/i18n/i18n";

const { t, n, te, d } = i18n.global;

interface Datum {
  [i: number]: never;
  [k: string]: any;
}

/**
 * Returns layout config
 * @returns {object}
 */
export const config = computed(() => {
  return store.getters.layoutConfig();
});

/**
 * Set the sidebar display
 * @returns {boolean}
 */
export const displaySidebar = computed(() => {
  return store.getters.layoutConfig("sidebar.display");
});

/**
 * Check if footer container is fluid
 * @returns {boolean}
 */
export const footerWidthFluid = computed(() => {
  return store.getters.layoutConfig("footer.width") === "fluid";
});

/**
 * Check if header container is fluid
 * @returns {boolean}
 */
export const headerWidthFluid = computed(() => {
  return store.getters.layoutConfig("header.width") === "fluid";
});

/**
 * Returns header left part type
 * @returns {string}
 */
export const headerLeft = computed(() => {
  return store.getters.layoutConfig("header.left");
});

/**
 * Set the aside display
 * @returns {boolean}
 */
export const asideDisplay = computed(() => {
  return store.getters.layoutConfig("aside.display") === true;
});

/**
 * Check if toolbar width is fluid
 * @returns {boolean}
 */
export const toolbarWidthFluid = computed(() => {
  return store.getters.layoutConfig("toolbar.width") === "fluid";
});

/**
 * Set the toolbar display
 * @returns {boolean}
 */
export const toolbarDisplay = computed(() => {
  return store.getters.layoutConfig("toolbar.display");
});

/**
 * Check if the page loader is enabled
 * @returns {boolean}
 */
export const loaderEnabled = computed(() => {
  return store.getters.layoutConfig("loader.display");
});

/**
 * Check if container width is fluid
 * @returns {boolean}
 */
export const contentWidthFluid = computed(() => {
  return store.getters.layoutConfig("content.width") === "fluid";
});

/**
 * Page loader logo image
 * @returns {string}
 */
export const loaderLogo = computed(() => {
  return process.env.BASE_URL + store.getters.layoutConfig("loader.logo");
});

/**
 * Check if the aside menu is enabled
 * @returns {boolean}
 */
export const asideEnabled = computed(() => {
  return store.getters.isMobile;
  // return !!store.getters.layoutConfig("aside.display");
});

/**
 * Set the aside theme
 * @returns {string}
 */
export const asideTheme = computed(() => {
  return store.getters.layoutConfig("aside.theme");
});

/**
 * Set the subheader display
 * @returns {boolean}
 */
export const subheaderDisplay = computed(() => {
  return store.getters.layoutConfig("toolbar.display");
});

/**
 * Set the aside menu icon type
 * @returns {string}
 */
export const asideMenuIcons = computed(() => {
  return store.getters.layoutConfig("aside.menuIcon");
});

/**
 * Light theme logo image
 * @returns {string}
 */
export const themeLightLogo = computed(() => {
  return process.env.BASE_URL + store.getters.layoutConfig("main.logo.light");
});

/**
 * Dark theme logo image
 * @returns {string}
 */
export const themeDarkLogo = computed(() => {
  return process.env.BASE_URL + store.getters.layoutConfig("main.logo.dark");
});

/**
 * Set the header menu icon type
 * @returns {string}
 */
export const headerMenuIcons = computed(() => {
  return store.getters.layoutConfig("header.menuIcon");
});

/**
 * Get current user's preferred language
 * @returns {string}
 */
export const currentLang = computed(() => {
  if (store.getters.currentUser.language === "ee") return "et";

  return store.getters.currentUser.language;
});

/**
 * Capitalize text
 * @returns {string}
 */
export const capitalizeText = (text: string): string => {
  return text && text[0].toUpperCase() + text.slice(1);
};

/**
 * Translate text
 * @returns {string}
 */
export const translate = (text: string): string => {
  return te(text) ? t(text) : text;
};

/**
 * format numbers
 * @returns {string}
 */
export const formatNumbers = (number: number): string => {
  return n(parseFloat(number.toString()), "currency");
};

/**
 * format dates
 * @returns {string}
 */
export const formatDates = (
  date: string | number | Date,
  style: string,
  locale: string
): string => {
  return d(date, style, locale);
};

export const setDateFormat = (lang: string): string => {
  switch (lang) {
    case "en":
      return "MM/DD/YYYY";

    case "et":
      return "DD.MM.YYYY";

    case "de":
      return "DD.MM.YYYY";

    case "au":
      return "MM/DD/YYYY";

    case "lt":
      return "YYYY-MM-DD";

    case "ru":
      return "YYYY-MM-DD";

    case "uk":
      return "DD/MM/YYYY";

    default:
      return "MM/DD/YYYY";
  }
};

/**
 * Get random background color
 * @returns {string}
 */
export const randomBackgroundColor = (): string => {
  const bgArray = [
    "bg-primary",
    "bg-success",
    "bg-info",
    "bg-info",
    "bg-warning",
    "bg-danger",
  ];

  const randomBg = Math.floor(Math.random() * bgArray.length);

  return bgArray[randomBg];
};

/**
 * Get first char of string
 * @returns {string}
 */
export const handleGetFirstChar = (text: string): string => {
  return text.charAt(0);
};

/**
 * Group array items by key
 * @returns {string}
 */
export const groupBy = (array: Datum[], key: string) => {
  const result = {};
  array.forEach((item) => {
    if (!result[item[key]]) {
      result[item[key]] = [];
    }
    result[item[key]].push(item);
  });
  return result;
};

/**
 * Truncate long text with ellipsis
 * @returns {string}
 */
export const truncate = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};
