import * as Cookies from "js-cookie";

const ACCESS_TOKEN = "access_token" as string;
const REFRESH_TOKEN = "refresh_token" as string;

/**
 * @description get cookie
 * @params key: string
 */
export const getCookie = (key): string => {
  // console.log(key);
  return Cookies.get(key);
};

/**
 * @description save token into cookies
 * @param key: string
 * @param value: object
 *
 */
export const saveCookie = (value: object | string, key?: string): void => {
  if (key === "bw-state") {
    Cookies.set(key, value, {
      expires: 15,
      secure: process.env.NODE_ENV === "production" ? true : false,
    });
  } else {
    // Convert expires_in to days; 1 day = 86400 seconds
    if (value["access_token"] || value["refresh_token"]) {
      const expiresIn = value["expires_in"] / 86400;
      Cookies.set("access_token", value["access_token"], {
        expires: expiresIn,
        secure: process.env.NODE_ENV === "production" ? true : false,
      });
      Cookies.set("refresh_token", value["refresh_token"], {
        expires: 7,
        secure: process.env.NODE_ENV === "production" ? true : false,
      });
    } else {
      Cookies.set(key, value);
    }
  }
};

/**
 * @description remove xsrf cookie
 */
export const destroyCookie = (key: string): void => {
  Cookies.remove(key);
};

export default { getCookie, saveCookie, destroyCookie };
