import TitaniumApiService from "@/core/services/TitaniumApiService";
import {
  Actions,
  Mutations as ContractMutations,
} from "@/store/enums/ContractorEnums";
import { Mutations } from "@/store/enums/StoreEnums";
import {
  Contract,
  ContractorInfo,
} from "@/store/interface/ContractorInterfaces";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ContractorModule extends VuexModule
  implements ContractorInfo {
  contracts = [] as Contract[];

  /**
   * Get contracts
   * @returns array
   */
  get getContracts(): Contract[] {
    return this.contracts;
  }

  @Mutation
  [ContractMutations.ADD_CONTRACT](payload) {
    console.log(payload.contract);
    this.contracts = [...this.contracts, payload.contract];
    // this.contracts.push(payload.contract);
  }

  @Mutation
  [ContractMutations.SET_CONTRACTS](payload: any) {
    this.contracts = payload;
  }

  @Mutation
  [ContractMutations.UPDATE_CONTRACT](payload) {
    Object.assign(this.contracts[payload.index], payload.data);
  }

  @Mutation
  [ContractMutations.REMOVE_CONTRACT](index: number) {
    this.contracts.splice(index, 1);
  }

  @Action
  [Actions.CREATE_CONTRACT](contract: object) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.post("contracts", contract)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(ContractMutations.ADD_CONTRACT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.READ_ALL_CONTRACT]() {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.post("old-contracts", {})
        .then(({ data }) => {
          this.context.commit(ContractMutations.SET_CONTRACTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  }

  @Action
  [Actions.READ_USER_CONTRACT](payload) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.query("contracts", { params: payload })
        .then(({ data }) => {
          this.context.commit(ContractMutations.SET_CONTRACTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  }

  @Action
  [Actions.UPDATE_CONTRACT](contract) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.update("contracts", "batch", contract)
        .then(({ data }) => {
          const index = this.contracts.findIndex((p) => p.id === contract.id);
          this.context.commit(ContractMutations.UPDATE_CONTRACT, {
            index,
            data,
          });
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_CONTRACT](contract) {
    return new Promise<void>((resolve, reject) => {
      TitaniumApiService.delete("contracts", contract.id)
        .then(({ data }) => {
          const index = this.contracts.findIndex((p) => p.id === contract.id);
          this.context.commit(ContractMutations.REMOVE_CONTRACT, index);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
}
