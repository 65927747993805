
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseToaster",
  props: {
    title: {
      type: String,
      default: "Updating..",
    },
    buttonTitle: {
      type: String,
      default: 'OK'
    },
    allowUndo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const clicked = () => {
      emit("undoClicked");
    };
    return {
      clicked,
    };
  },
});
