import { App } from "vue";
import store from "@/store/index";
import axios from "axios";
import VueAxios from "vue-axios";
import { Token } from "@/core/enums/TokenEnums";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import CookieService from "./CookieService";
import { Actions } from "@/store/enums/StoreEnums";

// const store = useStore();

/**
 * @description service to call HTTP request via Axios
 */
class AuthApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_AUTH_API_URL,
  });

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    AuthApiService.vueInstance = app;
    AuthApiService.vueInstance.use(VueAxios, axios);
    AuthApiService.axiosInstance.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setRequestHeader(): void {
    AuthApiService.axiosInstance.interceptors.request.use(
      function(config) {
        console.log("auth api request intercepted");
        const token = CookieService.getCookie("access_token");

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  /**
   * @description intercept HTTP response and check for error
   */
  public static checkAuthorizedResponse(): void {
    AuthApiService.axiosInstance.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        console.log("response intercepted");
        return response;
      },
      async function(error) {
        // console.log(error.config._retry);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (
          error.config &&
          error.response.status === 401 &&
          !error.config.__isRetry
        ) {
          // __isRetry property is set to prevent infinite 401 loop
          error.config.__isRetry = true;
          const refreshToken = CookieService.getCookie("refresh_token");

          if (!refreshToken) {
            throw new Error("Session expired! Please relog.");
          }

          try {
            await store.dispatch(Actions.REFRESH_TOKEN, {
              [Token.refreshToken]: refreshToken,
            });

            return AuthApiService.axiosInstance.request(error.config);
          } catch (e) {
            return Promise.reject(e);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return AuthApiService.axiosInstance.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return AuthApiService.axiosInstance
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return AuthApiService.axiosInstance.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return AuthApiService.axiosInstance.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return AuthApiService.axiosInstance.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string, slug: string): Promise<AxiosResponse> {
    return AuthApiService.axiosInstance
      .delete(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  }
}

export default AuthApiService;
