import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AggridModule from "@/store/modules/AggridModule";
import AdminModule from "@/store/modules/AdminModule";
import AlertModule from "@/store/modules/AlertModule";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ContractorModule from "@/store/modules/ContractorModule";
import ProjectModule from "@/store/modules/ProjectModule";
import UserModule from "@/store/modules/UserModule";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";

// console.log(Cookies);

// config.rawError = true;

const store = createStore({
  modules: {
    AggridModule,
    AdminModule,
    AlertModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ContractorModule,
    ProjectModule,
    UserModule,
  },
  plugins: [
    createPersistedState({
      key: "bw-state",
      paths: ["AuthModule.user"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 5, secure: false }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: "user-tenants",
      paths: ["AuthModule.tenants"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 5, secure: false }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});

export default store;
