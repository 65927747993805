
<template>
  <div class="toast-container">
    <h3 class="mb-0">{{ title }}</h3>
    <button
      type="button"
      class="action btn"
      @click.stop="clicked"
      v-if="allowUndo"
    >{{ buttonTitle }}</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseToaster",
  props: {
    title: {
      type: String,
      default: "Updating..",
    },
    buttonTitle: {
      type: String,
      default: 'OK'
    },
    allowUndo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const clicked = () => {
      emit("undoClicked");
    };
    return {
      clicked,
    };
  },
});
</script>

<style scoped>
.toast-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  pointer-events: all;
}

.action {
  height: 40px;
  padding: 5px 10px !important;
  border-radius: 5px;
  color: #fff;
  cursor: pointer !important;
  border: thin solid currentColor !important;
}

.action:hover {
  color: #fff;
  border: thin solid currentColor !important;
  cursor: pointer !important;
  background-color: rgba(238, 238, 238, 0.158);
}
</style>
