import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { nextTick } from "vue";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import CookieService from "@/core/services/CookieService";
import { capitalizeText } from "@/core/helpers/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/layout/Layout.vue"),
    meta: { oldView: false },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: false,
        },
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      },
      {
        path: "/admin/manage-users",
        name: "manage-users",
        meta: {
          requiresAuth: false,
          oldView: false,
          allowedContractorPage: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "manage-users" */ "@/views/admin/manage-users/ManageUsers.vue"
          ),
      },
      {
        path: "/admin/manage-permissions",
        name: "manage-permissions",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "manage-permissions" */ "@/views/admin/manage-permissions/ManagePermissions.vue"
          ),
      },
      {
        path: "/admin/manage-roles",
        name: "manage-roles",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "manage-roles" */ "@/views/admin/manage-roles/ManageRoles.vue"
          ),
      },
      {
        path: "/examples",
        name: "example",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "example" */ "@/views/example/Example.vue"
          ),
      },
      {
        path: "/finances/purchase-invoices",
        name: "purchase-invoices",
        meta: { requiresAuth: true, allowedContractorPage: false },
        component: () =>
          import(
            /* webpackChunkName: "purchase-invoice" */ "@/views/finances/PurchaseInvoice/PurchaseInvoice.vue"
          ),
      },
      {
        path: "/finances/sales-invoices",
        name: "sales-invoices",
        meta: { requiresAuth: true, allowedContractorPage: false },
        component: () =>
          import(
            /* webpackChunkName: "sales-invoice" */ "@/views/finances/SaleInvoice/SaleInvoice.vue"
          ),
      },
      {
        path: "/contracts",
        name: "contracts",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/views/contractor/pages/Contract.vue"
          ),
      },
      {
        path: "/rfq/:id",
        name: "rfq",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: true,
        },
        component: () =>
          import(/* webpackChunkName: "rfq-supplier" */ "@/views/rfq/RFQ.vue"),
        children: [
          {
            path: "overview",
            name: "overview",
            meta: { oldView: false },
            component: () =>
              import(
                /* webpackChunkName: "overview" */ "@/views/rfq/overview/Overview.vue"
              ),
          },
          {
            path: "rounds",
            name: "rounds",
            meta: { oldView: false },
            component: () =>
              import(
                /* webpackChunkName: "rounds" */ "@/views/rfq/rounds/Rounds.vue"
              ),
          },
          {
            path: "responses",
            name: "responses",
            meta: { oldView: false },
            component: () =>
              import(
                /* webpackChunkName: "responses" */ "@/views/rfq/responses/Responses.vue"
              ),
          },
          {
            path: "supplier",
            name: "supplier",
            meta: {
              requiresAuth: true,
              oldView: false,
              allowedContractorPage: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "supplier" */ "@/views/rfq/supplier/Supplier.vue"
              ),
          },
        ],
      },
      {
        path: "/:id/profile",
        name: "profile",
        meta: {
          requiresAuth: true,
          oldView: false,
          allowedContractorPage: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "profile-home" */ "@/views/pages/Profile.vue"
          ),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            meta: { oldView: false },
            component: () =>
              import(
                /* webpackChunkName: "profile-overview" */ "@/views/pages/profile/Overview.vue"
              ),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () =>
              import(
                /* webpackChunkName: "account-settings" */ "@/views/pages/profile/Settings.vue"
              ),
          },
          // DELETE BELOW ROUTES
          // {
          //   path: "campaigns",
          //   name: "profile-campaigns",
          //   component: () => import("@/views/pages/profile/Campaigns.vue"),
          // },
          // {
          //   path: "documents",
          //   name: "profile-documents",
          //   component: () => import("@/views/pages/profile/Documents.vue"),
          // },
          // {
          //   path: "connections",
          //   name: "profile-connections",
          //   component: () => import("@/views/pages/profile/Connections.vue"),
          // },
        ],
      },
      {
        path: "/:id/account",
        name: "account",
        meta: { requiresAuth: true, allowedContractorPage: true },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/account/Account.vue"
          ),
        beforeEnter: async (to, _, next) => {
          console.log(to.params.id);
          await store.dispatch(Actions.GET_PROFILE, to.params.id);
          next();
        },
        children: [
          // {
          //   path: "overview",
          //   name: "account-overview",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "account-overview" */ "@/views/account/Overview.vue"
          //     ),
          // },
          // Keep below?
          {
            path: "/two-factor-auth",
            name: "modals-wizards-two-factor-auth",
            component: () =>
              import(
                /* webpackChunkName: "two-factor" */ "@/views/modals/wizards/TwoFactorAuth.vue"
              ),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () =>
              import(
                /* webpackChunkName: "account-settings" */ "@/views/account/Settings.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    props: true,
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "@/views/auth/SignIn.vue"),
  },
  {
    path: "/sign-up/:token?",
    name: "sign-up",
    props: true,
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "@/views/auth/SignUp.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    props: true,
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/auth/ResetPassword.vue"
      ),
  },
  {
    path: "/verify-email/:token",
    name: "verify-email",
    props: true,
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () =>
      import(
        /* webpackChunkName: "verify-email" */ "@/views/auth/VerifyEmail.vue"
      ),
  },
  {
    path: "/link/:token",
    name: "invite-external",
    props: true,
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () =>
      import(
        /* webpackChunkName: "invite-user" */ "@/views/auth/InviteUser.vue"
      ),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: { requiresAuth: false, allowedContractorPage: true },
    component: () => import("@/views/error/Error404.vue"),
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/404",
  // },
  {
    path: "/:pathMatch(.*)*",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "oldview-layout" */ "@/layout/OldViewLayout.vue"
      ),
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "Titanium",
        meta: {
          requiresAuth: true,
          oldView: true,
          allowedContractorPage: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "titanium" */ "@/views/titanium/Titanium.vue"
          ),
        props: (route) => {
          console.log("xxx", route);
          const titaniumRouteMap = {
            "/finances/sales": "finance/invoices",
          };
          const fullPath = route.fullPath;
          const path = titaniumRouteMap[fullPath]
            ? titaniumRouteMap[fullPath]
            : fullPath.replace(/^\//, "");

          return { titaniumPath: path, routeTime: new Date().getTime() };
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const state = JSON.parse(CookieService.getCookie("bw-state"));
    if (
      !store.getters.isEmailVerified ||
      !store.getters.isUserAuthenticated ||
      Object.keys(state.AuthModule.user).length === 0
    ) {
      store.dispatch(Actions.LOGOUT).then(() => {
        document.title = "Bauwise";
        next({ path: "/sign-in", query: { redirect: to.fullPath } });
      });
    } else {
      store
        .dispatch(Actions.VERIFY_AUTH)
        .then(() => {
          if (store.getters.availableTenants.length > 0) {
            next();
          } else {
            if (
              !to.matched.some((record) => record.meta.allowedContractorPage)
            ) {
              next("/contracts");
            } else {
              next();
            }
          }
        })
        .catch(() => {
          console.log("error");
          next({ path: "/sign-in" });
        });
    }
  } else {
    next();
  }

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach(async (to, from) => {
  await nextTick();
  document.title = `Bauwise ${
    store.getters.isUserAuthenticated && store.getters.currentUser.tenant
      ? "| " + capitalizeText(store.getters.currentUser.tenant)
      : ""
  }`;
});

export default router;
