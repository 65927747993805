import { nextTick } from "vue";
import { Modal } from "bootstrap";

const removeModalBackdrop = () => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const removeDrawerBackdrop = () => {
  if (document.querySelectorAll(".drawer-overlay").length) {
    document.querySelectorAll(".drawer-overlay").forEach((item) => {
      item.remove();
    });
  }
};

const showModal = async (
  modalId: string,
  keyboard?: boolean,
  backdrop?: string
) => {
  await nextTick;
  // Get Modal Element Instancce and hides it
  const createModalEl = document.getElementById(modalId) as any;
  const modal = Modal.getOrCreateInstance(createModalEl, {
    keyboard: keyboard,
    backdrop: backdrop,
  });

  modal.show();
  return Promise.resolve();
};

const hideModal = async (modalId: string) => {
  await nextTick;
  // Get Modal Element Instancce and hides it
  const createModalEl = document.getElementById(modalId) as any;
  const modal = Modal.getOrCreateInstance(createModalEl);

  modal.hide();
  return Promise.resolve();
};

export { removeModalBackdrop, removeDrawerBackdrop, hideModal, showModal };
