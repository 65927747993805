import ApiService from "@/core/services/ApiService";
import {
  Actions,
  Mutations as ProjectMutations,
} from "@/store/enums/ProjectEnums";
import { Mutations } from "@/store/enums/StoreEnums";
import { ProjectInfo, Project } from "@/store/interface/StoreInterfaces";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ProjectModule extends VuexModule implements ProjectInfo {
  projects = [] as Project[];

  /**
   * Get projects
   * @returns array
   */
  get getProjects(): Project[] {
    return this.projects;
  }

  @Mutation
  [ProjectMutations.ADD_PROJECT](payload) {
    console.log(payload.project);
    this.projects = [...this.projects, payload.project];
    // this.projects.push(payload.project);
  }

  @Mutation
  [ProjectMutations.SET_PROJECTS](payload: any) {
    this.projects = payload;
  }

  @Mutation
  [ProjectMutations.UPDATE_PROJECT](payload) {
    Object.assign(this.projects[payload.index], payload.data);
  }

  @Mutation
  [ProjectMutations.REMOVE_PROJECT](index: number) {
    this.projects.splice(index, 1);
  }

  @Action
  [Actions.CREATE_PROJECT](project: object) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("projects", project)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(ProjectMutations.ADD_PROJECT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.READ_ALL_PROJECT]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("old-projects", {})
        .then(({ data }) => {
          this.context.commit(ProjectMutations.SET_PROJECTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.READ_USER_PROJECT](UID) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("user-projects", UID)
        .then(({ data }) => {
          this.context.commit(ProjectMutations.SET_PROJECTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  }

  @Action
  [Actions.UPDATE_PROJECT](project) {
    return new Promise<void>((resolve, reject) => {
      ApiService.update("projects", "batch", project)
        .then(({ data }) => {
          const index = this.projects.findIndex((p) => p.id === project.id);
          this.context.commit(ProjectMutations.UPDATE_PROJECT, { index, data });
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PROJECT](project) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("projects", project.id)
        .then(({ data }) => {
          const index = this.projects.findIndex((p) => p.id === project.id);
          this.context.commit(ProjectMutations.REMOVE_PROJECT, index);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
}
