import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/clean";
import store from "@/store";

import ElementPlus from "element-plus";
import i18n from "@/core/i18n/i18n";
import Toast, { PluginOptions, POSITION } from "vue-toastification";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import AuthApiService from "@/core/services/AuthApiService";
import TitaniumApiService from "@/core/services/TitaniumApiService";

import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { VueReCaptcha } from "vue-recaptcha-v3";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import "vue-toastification/dist/index.css";
import "element-plus/dist/index.css";

// import "@/views/titanium/titanium_wrapper.css";

const app = createApp(App);

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  draggable: false,
  maxToasts: 10,
  newestOnTop: true,
  pauseOnFocusLoss: false,
};

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Toast, options);
app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    useEnterprise: false,
    autoHideBadge: false,
  },
});
ApiService.init(app);
AuthApiService.init(app);
TitaniumApiService.init(app);

// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

window["vue_instance"] = {
  env: process.env,
};

app.mount("#app");
