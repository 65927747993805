import ApiService from "@/core/services/ApiService";
import AuthApiService from "@/core/services/AuthApiService";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import {
  Actions as StoreActions,
  Mutations as StoreMutations,
} from "@/store/enums/StoreEnums";
import {
  Actions as UserActions,
  Mutations as UserMutations,
} from "@/store/enums/UserEnums";

import {
  UserData,
  UserProfile,
  UserProfileInfo,
} from "@/store/interface/StoreInterfaces";

@Module
export default class UserModule extends VuexModule implements UserProfileInfo {
  profile = {} as UserProfile;
  users = [] as UserData[];

  /**
   * Get current user profile object
   * @returns User
   */
  get currentUserProfile(): UserProfile {
    console.log(this.profile);
    return this.profile;
  }

  @Action
  [UserActions.UPDATE_PROFILE](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post(`me`, payload)
        .then(({ data }) => {
          this.context.commit(StoreMutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(StoreMutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [UserActions.VERIFY_CURRENT_PASSWORD](payload) {
    return new Promise<boolean>((resolve, reject) => {
      // Log user in to verify current password is correct
      /* eslint-disable @typescript-eslint/camelcase */

      AuthApiService.post("login", payload)
        .then(({ data }) => {
          const tokenData = {
            access_token: data["access_token"],
            expires_in: data["expires_in"],
            refresh_token: data["refresh_token"],
            token_type: data["token_type"],
          };
          this.context.commit(StoreMutations.SET_TOKEN, tokenData);
          this.context.commit(StoreMutations.SET_TENANTS, data["tenants"]);
          this.context.commit(StoreMutations.SET_AUTH);
          this.context.commit(StoreMutations.SET_USER, data["user"]);
          resolve(true);
        })
        .catch(({ response }) => {
          // this.context.commit(StoreMutations.SET_ERROR, response.data.message);
          reject(new Error("Current password is incorrect!"));
        });
    });
  }

  @Action
  [UserActions.CHANGE_CURRENT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      AuthApiService.post(`me`, payload)
        .then(({ data }) => {
          this.context.commit(StoreMutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(StoreMutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
}
