// action types
enum Actions {
  UPDATE_PROFILE = "updateProfileActions",
  VERIFY_CURRENT_PASSWORD = "verifyCurrentPassword",
  CHANGE_CURRENT_PASSWORD = "changeCurrentPassword",
}

// mutation types
enum Mutations {
  UPDATE_PROFILE = "updateProfileMutation",
}

export { Actions, Mutations };
